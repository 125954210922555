import { ButtonHTMLAttributes } from "react";
import CircularLoader from "@/Components/CircularLoader";
import "./button.scss";

export default function Button({
    className = "",
    disabled,
    children,
    loading,
    transparent = false,
    spanLabel = true,
    ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
    transparent?: boolean;
    spanLabel?: boolean;
}) {
    return (
        <button
            {...props}
            className={`${transparent ? "" : "btn"} ${className ? className : ""} ${disabled ? "disabled" : ""}`}
            disabled={disabled}
        >
            {spanLabel ? (
                <span className="translate">{children}</span>
            ) : (
                children
            )}
            {loading && <CircularLoader />}
        </button>
    );
}
